<template>
    <form class="form-group" @submit.prevent="onSubmit">
        <div class="columns">
            <div class="column col-4">
                <div class="form-group">
                    <label class="form-label">Message Type</label>
                    <select class="form-select" v-model="messageType" required>
                        <option value="message" selected>Message</option>
                        <option value="image">Image</option>
                    </select>
                </div>
            </div>
            <div class="column col-4">
                <label class="form-label">Animation Type</label>
                <select class="form-select" v-model="animationType" required>
                    <option value="none" selected>None</option>
                    <option value="hearth">Hearth</option>
                    <option value="space">Space</option>
                </select>
            </div><div class="column col-4">
                <label class="form-label">Date</label>
                <input type="date" class="form-input" v-model="date" required>
            </div>
            <div class="column col-12">
                <label class="form-label">Message</label>
                <textarea class="form-control w-100" placeholder="I love you Julia" v-model="message" required></textarea>
            </div>
            <div class="column col-12 mt-2">
                <button class="btn btn-success w-100">Add Message</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ref } from '@vue/reactivity';

import { projectFirestore, timestamp, firebase } from '@/firebase/config';

export default {
    setup() {
        const message = ref(null);
        const messageType = ref(null);
        const animationType = ref(null);
        const date = ref(null);

        console.log(timestamp)

        const onSubmit = async () => {
            const messageObject = {
                message: message.value,
                messageType: messageType.value,
                animationType: animationType.value,
                date: firebase.firestore.Timestamp.fromDate(new Date(date.value))
            }

            await projectFirestore.collection('messages').add(messageObject);

            // Clear form
            message.value = '';
            messageType.value = '';
            animationType.value = '';
            date.value = '';
        }

        return {
            onSubmit,
            message,
            messageType,
            animationType,
            date
        }
    }
}
</script>

<style>

</style>