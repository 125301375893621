<template>
    <div class="background" :class="type"></div>
</template>

<script>
export default {
    props: ['type']
}
</script>

<style>
.background {
    background-image: url('../assets/background.jpg');
    background-size: cover;
    width: 480px;
    height: 320px;
}

.background.space {
    background-image: none;
    background: black;
}
</style>