import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAadedH0VpfuscY5I5KK9MA2I5144vYOXw",
  authDomain: "juliping-e34a5.firebaseapp.com",
  projectId: "juliping-e34a5",
  storageBucket: "juliping-e34a5.appspot.com",
  messagingSenderId: "686735255346",
  appId: "1:686735255346:web:678805b4f9ab4941ebd92b"
};

// Init firebase
firebase.initializeApp(firebaseConfig);

// Init firestore service
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { firebase, projectFirestore, timestamp }