<template>
  <div class="particle-container">
  </div>
</template>

<script>
import gsap from 'gsap';
import { onMounted } from '@vue/runtime-core';

export default {
    props: [ 'type' ],
    setup(props) {
        const iconTable = {
            'hearth': 'fas fa-heart',
            'space': 'fas fa-star',
            'none': ''
        }
        let prev_type = '';

        const anim_fadeUp = (p, rd) => {
            gsap.set(p, { scale: 0, x: 'random(0, 480)', y: 'random(0, 320)' });
            gsap.to(p, { scale: 'random(0.7, 1.4)', ease: 'back.out(2)', duration: 1 });
            gsap.to(p, { y: '-100%', ease: 'power2.in', duration: rd })
            gsap.to(p, { scale: 0, ease: 'back.in(2)', duration: 1, delay: rd, onComplete: function() {
                p.remove();
            }});
        }

        const anim_scaleInOutWiggle = (p, rd) => {
            gsap.set(p, { x: 'random(0, 480)', y: 'random(0, 320)', rotate: 'random(0, 360)', scale: 0 });
            gsap.to(p, { scale: 'random(0.9, 1.2)', ease: 'back.out', duration: 1 });
            gsap.to(p, { rotate: 360, ease: 'linear', duration: 6 });
            gsap.to(p, { scale: 0, ease: 'back.in', duration: 1, delay: rd, onComplete: function() {
                p.remove();
            }});
        }

        let interval = null;

        onMounted(() => {
            clearInterval(interval);
            interval = setInterval(() => {
                if (props.type != prev_type) {
                    $('.particle-container').html('');
                    prev_type = props.type;
                }
                generateParticle();
            }, 750);

            const generateParticle = () => {
                let particle = $(`<div class="particle ${props.type}"><i class="${iconTable[props.type]}"></i></div>`);
                particle.appendTo('.particle-container');

                let rd = Math.floor(Math.random() * 5 + 1);

                switch(props.type) {
                    case 'hearth':
                        anim_fadeUp(particle, rd);
                    break;
                    case 'space':
                        anim_scaleInOutWiggle(particle, rd);
                    break;
                }
            }
        });
    }
}
</script>

<style>
.particle {
    font-size: 32px;
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
}

.particle.hearth {
    color: red;
}

.particle.space {
    color: white;
    font-size: 16px;
}

.particle.none {
    display: none;
}
</style>