<template>
  <div class="date">{{ formattedDate }}</div>
</template>

<script>
import moment from 'moment-timezone';

import { computed, ref } from '@vue/reactivity';
export default {
    setup() {
        const time = ref(moment.tz('Europe/Amsterdam'));

        // const t = moment.tz('Europe/Amsterdam');

        const formattedDate = computed(() => {
            // let date = new Date(time.value);
            return time.value.format('D/M/YYYY - HH:mm:ss');
        });

        setInterval(() => {
            time.value = moment.tz('Europe/Amsterdam');
        }, 1000);

        return { formattedDate }
    }
}
</script>

<style scoped>
.date {
    font-size: 20px;
}
</style>