<template>
  <div class="home" :class="particleType">
    <div class="particles">
      <ParticleOverlay :type="particleType" />
    </div>
    <div class="info">
      <Date class="date" />
      <Weather city="Groningen" />
    </div>
    <div class="message">
      <div class="wrapper" id="message"></div>
    </div>
    <Background :type="particleType" />
  </div>
</template>

<script>
import Weather from '@/components/Weather.vue';
import Date from '@/components/Date.vue';
import ParticleOverlay from '@/components/ParticleOverlay.vue';
import Background from '@/components/Background.vue';

import getMessages from '@/composables/getMessages';
import getDateFunctions from '@/composables/getDateFunctions';

import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';

import gsap from 'gsap';

export default {
  components: { Weather, Date, ParticleOverlay, Background },
  setup() {
    const { isToday } = getDateFunctions();
    const { messages, error, load } = getMessages();
    const sortedMessages = ref([]);
    load();

    watch(messages, () => {
      sortedMessages.value = [];
      sortedMessages.value = messages.value.filter((message) => {
        return isToday(message.date.toDate())
      });

      reset();
    });

    const settings = {
      timer: 30 * 1e3
    }
    const tl = gsap.timeline();
    const particleType = ref('none');
    let interval = null;
    let i = 0;
    let current = '';

    const reset = () => {
      clearInterval(interval);

      // Check if there are any messages
      if (sortedMessages.value.length == 0) {
        sortedMessages.value.push({
          message: 'I Love You Julia! <3',
          messageType: 'message',
          animationType: 'none',
          id: 'default_backup'
        });
      }

      loop();
      interval = setInterval(loop, settings.timer);

      function loop() {
        tl.clear();
        i = (i + 1 >= sortedMessages.value.length) ? 0 : i + 1;
        let message = sortedMessages.value[i];

        if (message == undefined) return;

        let html = message.message;

        if (message.id == current) return;
        current = message.id;

        particleType.value = message.animationType;

        tl.to('.message', { opacity: 0, duration: 1, ease: 'power2.inOut' });
        tl.call(() => { document.getElementById('message').innerHTML = html });
        tl.to('.message', { opacity: 1, duration: 1, ease: 'power2.inOut' });
      }
    }


    return { sortedMessages, particleType }    
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@300;500;700&display=swap');

.home {
  font-family: 'Gluten', cursive;
  width: 480px;
  height: 320px;
  /* border: 1px solid red; */
  position: relative;
  z-index: -101;
}

.home.space {
  color: white;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.info {
  position: absolute;
  top: 98%;
  left: 50%;
  width: 100%;
  transform: translateY(-100%) translateX(-50%);
  font-weight: 300;

  text-align: center;
}

.info .date {
  margin-bottom: -6px;
  font-size: 18px;
}

.message {
  position: absolute;

  width: 480px;
  height: 320px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 200;
}

.message .wrapper {
  width: 90%;
  height: 80%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 28px;
  text-align: center;
}

.particles {
  width: 480px;
  height: 320px;

  top: 0;
  left: 0;

  position: absolute;

  z-index: 100;
}
</style>
