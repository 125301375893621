import { ref } from '@vue/reactivity';

import { projectFirestore } from '@/firebase/config';

const getMessages = () => {
    const messages = ref([]);
    const error = ref(null);

    const load = async () => {
        try {
            projectFirestore.collection('messages')
                .orderBy('date', 'desc')
                .onSnapshot((snap) => {
                    messages.value = snap.docs.map((doc) => {
                        return { ...doc.data(), id: doc.id }
                    });
                });

            // const res = await projectFirestore
            //     .collection('messages')
            //     .orderBy('date', 'desc')
            //     .get();
            // messages.value = res.docs.map((doc) => {
            //     return { ...doc.data(), id: doc.id };
            // });
        } catch(err) {
            error.value = err.message;
        }
    }

    return { messages, error, load };
}

export default getMessages;