<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

body {
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
