<template>
  <div class="container grid-xl">
    <div class="columns">
      <div class="column col-12">
        <MessageForm />
      </div>
      <div class="column col-12 mt-2">
        <MessageTable  :messages="messages" />
      </div>
    </div>
  </div>
</template>

<script>
import MessageForm from '@/components/Dashboard/MessageForm';
import MessageTable from '@/components/Dashboard/MessageTable';

import getMessages from '@/composables/getMessages';

export default {
  components: { MessageForm, MessageTable },
  setup() {
    const { messages, load, error } = getMessages();

    load();

    return { messages, error }
  }
}
</script>

<style>

</style>
