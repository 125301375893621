<template>
    <div class="message-table">
        <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Message</th>
                        <th>Message Type</th>
                        <th>Animation Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="message in messages" :key="message.id" :class="[ isToday(message.date.toDate()) ? 'active' : '' ]">
                        <td>{{ message.id }}</td>
                        <td>{{ formatDate(message.date.toDate()) }}</td>
                        <td>{{ message.message }}</td>
                        <td>{{ message.messageType }}</td>
                        <td>{{ message.animationType }}</td>
                        <td>
                            <button class="btn btn-error" @click="deleteMessage(message.id)">Delete</button>
                        </td>
                    </tr>
                </tbody>
        </table>
    </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config';

import { useRouter } from 'vue-router';

import getDateFunctions from '@/composables/getDateFunctions'

export default {
    props: ['messages'],
    setup() {
        const router = useRouter();

        const { formatDate, isToday } = getDateFunctions();

        const deleteMessage = async (id) => {
            await projectFirestore.collection('messages')
                .doc(id)
                .delete();
        }

        return { formatDate, isToday, deleteMessage }
    }
}
</script>

<style>

</style>