<template>
  <div class="weather">
    <i :class="weather_icon"></i>  {{ city }} - {{ temp }} &deg;c
  </div>
</template>

<script>
// Lib
import { ref } from '@vue/reactivity'
import axios from 'axios'
import weatherIcons from '@/assets/weather_icons.json'

export default {
    props: ['city'],
    setup(props) {
        const OPENWEATHER_API = 'cfa70f7d4f6375979a954710aed7e169'

        const temp = ref(0);
        const weather_icon = ref('');

        const updateWeather = async () => {
            const res = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${props.city}&appid=${OPENWEATHER_API}`);
            temp.value = (res.data.main.temp - 273.15).toFixed(0);

            let prefix = 'wi wi-';
            let code = res.data.weather[0].id;
            let icon = weatherIcons[code].icon;

            if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
                icon = 'day-' + icon;
            }

            weather_icon.value = prefix + icon;
        }
        
        updateWeather();
        setInterval(updateWeather, 900000);

        return { temp, weather_icon }
    }
}
</script>

<style scoped>
.weather {
    font-size: 22px;
}
</style>